*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.hero
{
width: 100%;
height: 100vh;
}
.content{
    color: #fbfcfd;
    position:absolute;
    top:45%;
    left:45%;
    transform: translateY(-50%);
    z-index: 2;

}


h1{
    font-size:60px;
    margin: 10px 0 30px;
    line-height: 80px;
    font-family: 'Inter', sans-serif; 
    
}

h1::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

h1:hover::before {
  transform: scaleX(1);
  transform:scaleY(0.1);
  transform-origin: bottom left;
}

h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: rgb(187, 136, 255);
  z-index: -1;
  transition: transform .6s ease;
}
small{
    font-size: 30px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}